import SvgCalendar from 'assets/image/icons/home/calendar.svg';
import SvgSuitCase from 'assets/image/icons/home/suitcase.svg';
import SvgBasket from 'assets/image/icons/home/basket.svg';
import SvgShop from 'assets/image/icons/home/shop.svg';
import SvgMonitor from 'assets/image/icons/home/monitor.svg';
import SvgAnalytic from 'assets/image/icons/home/analytic.svg';
import SvgLike from 'assets/image/icons/home/like.svg';
import SvgCall from 'assets/image/icons/home/call.svg';
import SvgUser from 'assets/image/icons/home/user.svg';

import Service1 from 'assets/image/pages/home/f-1.png';
import Service2 from 'assets/image/pages/home/f-2.png';
import Service3 from 'assets/image/pages/home/f-3.png';

export const featureItems = [
  {
    Icon: SvgCalendar,
    label: 'Calendar & Appointments',
  },
  {
    Icon: SvgShop,
    label: 'Marketing Tools',
  },
  {
    Icon: SvgAnalytic,
    label: 'Performance Snapshots',
  },
  {
    Icon: SvgMonitor,
    label: 'Desktop and Mobile app',
  },
  {
    Icon: SvgSuitCase,
    label: 'Business Hours',
  },
  {
    Icon: SvgBasket,
    label: 'GPS Directions',
  },
];

export const overviewItems = [
  {
    Icon: SvgAnalytic,
    label: 'Increase Your Income',
    description: 'Increase your income immediately by never missing calls again. No more lost jobs because you could not answer your phone.',
  },
  {
    Icon: SvgLike,
    label: 'Separate business calls from personal calls',
    description: 'Use a Voicio dedicated business number and keep your personal number private.',
  },
  {
    Icon: SvgCall,
    label: 'Look and sound professional',
    description: 'Create the perception of a large company with a personal secretary answering your calls.',
  },
  {
    Icon: SvgUser,
    label: 'Never miss a call again',
    description: 'Your AI receptionist is available 24/7 so you will never miss a call again.',
  },
];

export const serviceItems = [
  {
    image: Service1,
    title: 'Garge Doors',
    headline: 'The tools you need, where you need them.',
    description:
      'Businesses like yours need more. To help you keep your momentum Voicio delivers two unique experiences.',
  },
  {
    image: Service2,
    title: 'House Cleaning',
    headline: 'The tools you need, where you need them.',
    description:
      'Businesses like yours need more. To help you keep your momentum Voicio delivers two unique experiences.',
  },
  {
    image: Service3,
    title: 'Plumbing',
    headline: 'The tools you need, where you need them.',
    description:
      'Businesses like yours need more. To help you keep your momentum Voicio delivers two unique experiences.',
  },
  {
    image: Service1,
    title: 'Repairing',
    headline: 'The tools you need, where you need them.',
    description:
      'Businesses like yours need more. To help you keep your momentum Voicio delivers two unique experiences.',
  },
  {
    image: Service2,
    title: 'Cleaning',
    headline: 'The tools you need, where you need them.',
    description:
      'Businesses like yours need more. To help you keep your momentum Voicio delivers two unique experiences.',
  },
];

export const reviewItems = [
  {
    title: '"Voicio is not only something I recommend, <b>It\'s a must have.</b>"',
    name: 'Jennifer Smith',
    address: 'Los Angeles',
  },
  {
    title: '"Voicio is not only something I recommend, <b>It\'s a must have.</b>"',
    name: 'Jennifer Smith',
    address: 'Los Angeles',
  },
  {
    title: '"Voicio is not only something I recommend, <b>It\'s a must have.</b>"',
    name: 'Jennifer Smith',
    address: 'Los Angeles',
  },
  {
    title: '"Voicio is not only something I recommend, <b>It\'s a must have.</b>"',
    name: 'Jennifer Smith',
    address: 'Los Angeles',
  },
  {
    title: '"Voicio is not only something I recommend, <b>It\'s a must have.</b>"',
    name: 'Jennifer Smith',
    address: 'Los Angeles',
  },
];
