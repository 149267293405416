import styled from 'styled-components/macro';

const Overview = styled.div`
  margin-top: 50px;
  padding: 0px 60px;

  @media (min-width: 1500px) {
    padding: 0px 80px;
  }
  @media (max-width: 575px) {
    margin-top: 10px;
    padding: 0px 10px;
  }

  .overview-card-wrapper {
    margin-top: 30px;
    padding: 0px 20px;
    @media (max-width: 767px) {
      margin-top: 15px;
      padding: 0px 15px;
    }
  }
`;

Overview.Card = styled.div`
  padding: 20px 24px 24px;
  background-color: #ffffff;
  border: 3px solid #f3f3f3;
  box-shadow: 0px 28px 45px rgba(0, 0, 0, 0.0557426);
  border-radius: 31px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
  cursor: pointer;
  transition: all 0.35s;
  text-align: center;

  @media (max-width: 1499px) {
    height: 240px;
  }
  @media (max-width: 1199px) {
    height: 190px;
  }
  @media (max-width: 767px) {
    height: 240px;
  }
  @media (max-width: 575px) {
    padding: 12px 14px;
    height: 170px;
    svg {
      width: 18px;
      height: auto;
    }
  }

  h2 {
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.3px;
    margin-top: 8px;
    margin-bottom: 10px;

    @media (max-width: 575px) {
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0;
      margin-bottom: 4px;
    }
  }
  p {
    font-size: 16px;
    line-height: 20px;
    color: #757575;
    margin-bottom: 0px;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &:hover, &.active {
    background-color: #04b3c0;
    h2,
    p {
      color: #ffffff;
    }
    svg path {
      fill: #ffffff;
    }
  }
`;

export default Overview;
