import React from 'react';
import { Row, Col } from 'react-bootstrap';
// import { Button } from 'components';
import Support from './style';
// import SupportGuardian from 'assets/image/pages/home/support-guardian.png';
// import SupportMedium from 'assets/image/pages/home/support-medium.png';
// import SupportTechCrunch from 'assets/image/pages/home/support-tech-crunch.png';
// import SupportYahoo from 'assets/image/pages/home/support-yahoo.png';
import SupportFeature from 'assets/image/pages/home/support-feature.png';
import Rect from 'assets/image/pages/home/rect.png';

const SupportSection = () => {
  return (
    <Support>
      <Support.Card>
        <Row className="bg-green">
          <Col lg={5}>
            <Support.Content>
              <img src={Rect} style={{ pointerEvents: 'none' }} />
              <h2>
                Let our AI
                <br />
                help you do what matters.
              </h2>
              <p className="mt-4">
                The most advanced AI receptionist ever
                built, now in the palm of your hands.
              </p>
              <p className="mt-4">
                <strong>Ready to start automating your business?</strong><br />
                Email us at <a style={{color: '#fff', textDecoration:'underline'}} href="mailto:humans@voicio.com">humans@voicio.com</a>.
              </p>
              {/* <Button
                large
                round
                backgroundColor={'#2f2f2f'}
                hoverBackgroundColor={'#4e4e4e'}
                className="mt-4"
              >
                Try For Free <span className="ms-2">&#10230;</span>
              </Button> */}
            </Support.Content>
          </Col>
          <Col lg={7}>
            <Support.Image>
              <img src={SupportFeature} />
            </Support.Image>
          </Col>
        </Row>
      </Support.Card>
    </Support>
  );
};

export default SupportSection;