import React from 'react';
import { PageWrapper } from 'components';
import Banner from 'templates/home/banner';
import Overview from 'templates/home/overview';
// import Service from 'templates/home/service';
import Feature from 'templates/home/feature';
// import Review from 'templates/home/review';
import Support from 'templates/home/support';
import Footer from 'templates/footer';

const Home: React.VFC = () => {
  return (
    <PageWrapper>
      <div className="home">
        <Banner />
        <Overview />
        {/* <Service /> */}
        <Feature />
        {/* <Review /> */}
        <Support />
        <Footer />
      </div>
    </PageWrapper>
  );
};

export default Home;
