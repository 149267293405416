import styled from 'styled-components/macro';

const Feature = styled.div`
  position: relative;
  .feature-phone {
    img {
      max-width: 100%;
    }
  }
`;

Feature.ItemList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  @media (max-width: 575px) {
    padding: 20px;
    h3 {
      text-align: center;
    }
  }
`;

Feature.Item = styled.div`
  background: #ffffff;
  border: 3px solid #f3f3f3;
  border-radius: 33px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding: 7px 30px 7px 10px;
  width: 90%;
  cursor: pointer;
  span {
    font-weight: 700;
  }
  @media (max-width: 575px) {
    width: 100%;
  }
  .icon-wrapper {
    width: 45px;
    height: 45px;
    background-color: #cff0f2;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    border-radius: 50%;
    img {
      width: 22px;
      height: 22px;
    }
  }
  &.active {
    background-color: #04b3c0;
    border-color: #04b3c0;
    box-shadow: 0px 28px 45px rgba(4, 179, 192, 0.237911);
    span {
      color: white;
    }
    .icon-wrapper {
      background-color: white;
    }
  }
`;

export default Feature;
