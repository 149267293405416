import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import classnames from 'classnames';
import { overviewItems } from 'data/pages/home';
import Overview from './style';

const OverviewSection = () => {
  const [selected, setSelected] = useState(overviewItems[1]);

  return (
    <Overview>
      <Row>
        {overviewItems.map(({Icon, ...item}, idx) => (
          <Col
            xs={12}
            sm={6}
            xl={3}
            key={`overview-${idx}`}
            className="overview-card-wrapper"
          >
            <Overview.Card
              className={classnames({ active: selected === item })}
              onClick={() => setSelected(item)}
            >
              <div className="overview-icon">
                <Icon />
              </div>
              <div className="overview-feature">
                <h2>{item.label}</h2>
                <p>{item.description}</p>
              </div>
            </Overview.Card>
          </Col>
        ))}
      </Row>
    </Overview>
  );
};

export default OverviewSection;
