import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import classnames from 'classnames';
import { featureItems } from 'data/pages/home';
import Feature from './style';
import FeaturePhone from 'assets/image/pages/home/feature-phone.png';

const FeatureSection = () => {
  const [selected, setSeelected] = useState(featureItems[0]);

  return (
    <Feature>
      <Row>
        <Col lg={{ span: 4, offset: 1 }}>
          <Feature.ItemList>
            <div>
              <h3 className="mb-5">
                Powerful AI and intentional
                 tools designed with your success in mind. 
              </h3>
              {featureItems.map(({Icon, ...item}, idx) => (
                <Feature.Item
                  key={`home-feature-${idx}`}
                  className={classnames({
                    active: item.label === selected.label,
                  })}
                  onClick={() => setSeelected(item)}
                >
                  <div className="d-flex align-items-center">
                    <div className="icon-wrapper">
                      <Icon />
                    </div>
                    <span>{item.label}</span>
                  </div>
                  <span>&#62;</span>
                </Feature.Item>
              ))}
            </div>
          </Feature.ItemList>
        </Col>
        <Col lg={{ span: 6, offset: 1 }}>
          <div className="feature-phone">
            <img src={FeaturePhone} />
          </div>
        </Col>
      </Row>
    </Feature>
  );
};

export default FeatureSection;
