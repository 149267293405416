import styled from 'styled-components/macro';

const Support = styled.div`
  margin-top: 50px;
  padding: 0px 150px;
  @media (max-width: 1440px) {
    padding: 80px;
  }
  @media (max-width: 1200px) {
    padding: 50px;
  }
  @media (max-width: 575px) {
    padding: 20px;
  }
`;

Support.Card = styled.div`
  position: relative;
  z-index: 1;
  border-radius: 20px;
  overflow: hidden;
  border: 2px solid #04b3c0;
  background-color: white;
  .bg-green {
    background-color: #04b3c0;
  }
`;

Support.Content = styled.div`
  padding: 50px;
  position: relative;
  border-bottom: 2px solid #04b3c0;
  @media (max-width: 992px) {
    padding: 30px;
  }
  h2 {
    color: white;
  }
  p {
    color: white;
  }
  .btn-try-free {
    border-radius: 30px;
    background-color: #2f2f2f;
    color: white;
    font-weight: 500;
    font-size: 1.2rem;
    padding: 10px 25px;
    cursor: pointer;
    z-index: 1;
    position: relative;
    &:hover {
      filter: brightness(130%);
    }
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    max-height: 100%;
  }
`;

Support.Image = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
  img {
    max-width: 100%;
  }
`;

Support.Company = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  img {
    max-width: 100%;
  }
  @media (max-width: 575px) {
    img {
      max-width: 70%;
    }
  }
`;

Support.CompanyTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  @media (max-width: 992px) {
    h4 {
      font-size: 1.7rem;
    }
  }
`;

export default Support;
