import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button } from 'components';
import ModalVideo from 'react-modal-video';
import Banner from './style';
import UnderlineImage from 'assets/image/pages/home/underline.png';
import FemaleImage from 'assets/image/pages/home/banner-female.png';

const BannerSection = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <Banner>
      <Row>
        <Col lg={6} md={12}>
          <div className="d-flex align-items-center h-100">
            <Banner.Content>
              <h1>Your Virtual A.I. Receptionist Has Arrived.</h1>
              <p>
                <b>Never miss a lead again.</b> The Voicio A.I. receptionist
                manages all your calls and appointments{' '}
                <span className="support">
                  24/7/365
                  <img src={UnderlineImage} alt="" />
                </span>
                so you can focus on your business.
              </p>
              <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="GCVuLZEqNnk" onClose={() => setOpen(false)} />
              <Button variant="secondary" onClick={()=> setOpen(true)} large round className="btn-continue">
                Watch Our Introduction &nbsp;<i className="fas fa-play"></i></Button>
            </Banner.Content>
          </div>
        </Col>
        <Col lg={6} md={12} className="pt-4">
          <Banner.Image>
            <img src={FemaleImage} alt="Voicio" />
          </Banner.Image>
        </Col>
      </Row>
      <Banner.Overlay />
    </Banner>
  );
};

export default BannerSection;
