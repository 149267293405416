import styled from 'styled-components/macro';

const Banner = styled.div`
  img {
    max-width: 100%;
  }
  background-color: #e1ebf0;
  position: relative;
  overflow: hidden;
  padding-top: 72px;
`;

Banner.Content = styled.div`
  padding: 100px;
  font-family: 'Gilroy', sans-serif;

  @media (max-width: 1440px) {
    padding: 80px;
  }
  @media (max-width: 1200px) {
    padding: 70px;
  }
  @media (max-width: 1100px) {
    padding: 50px;
  }
  @media (max-width: 992px) {
    text-align: center;
  }
  @media (max-width: 575px) {
    padding: 20px 20px 10px 20px;
  }
  h1 {
    font-size: 60px;
    line-height: 67px;
    color: #1D1D1D;
    margin-bottom: 32px;

    @media (max-width: 1440px) {
      font-size: 50px;
      line-height: 56px;
      margin-bottom: 28px;
    }
    @media (max-width: 1200px) {
      font-size: 45px;
      line-height: 50px;
      margin-bottom: 24px;
    }
    @media (max-width: 992px) {
      font-size: 38px;
      line-height: 42px;
    }
    @media (max-width: 575px) {
      font-size: 30px;
      line-height: 33px;
      margin-bottom: 19px;
    }
  }
  p {
    font-size: 20px;
    line-height: 28px;
    color: #1D1D1D;
    margin-bottom: 44px;

    @media (max-width: 575px) {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 28px;
    }
  }
  .support {
    font-weight: 700;
    color: #9646c3;$
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
    img {
      position: absolute;
      left: 0px;
      width: 100%;
      bottom: 0px;
    }
  }
  .btn-continue {
    z-index: 1;
  }
`;

Banner.Image = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  img {
    max-width: 120%;
  }
`;

Banner.Overlay = styled.div`
  position: absolute;
  bottom: -55px;
  right: 0px;
  left: 0px;
  height: 100%;
  background: radial-gradient(70% 100% at top, transparent 90%, white 90%);
  @media (max-width: 992px) {
    height: 200px;
    background: radial-gradient(70% 70% at top, transparent 90%, white 90%);
  }
`;

export default Banner;
